<template>
  <div>
    <el-table :data="tableData" border style="width:100%">
      <el-table-column prop="toefl_title" label="Test" min-width="160px">
        <template slot-scope="scope">
          <div v-if="scope.row.is_finished === 0 && !isAdmin">
            {{ scope.row.toefl_title }}
          </div>
          <router-link
            v-else
            :to="{ name: 'toeflResult', query: { id: scope.row.user_exam_id } }"
          >
            <i class="fa fa-eye"></i> {{ scope.row.toefl_title }}</router-link
          >
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Student" width="150px">
        <template slot-scope="scope">
          <router-link
            v-if="isAdmin"
            :to="{ name: 'ProfileForAdmin', params: { id: scope.row.user_id } }"
          >
            {{
              userMethods.displayName(scope.row.first_name, scope.row.last_name)
            }}
          </router-link>
          <router-link v-else :to="{ name: 'Profile' }">
            {{
              userMethods.displayName(scope.row.first_name, scope.row.last_name)
            }}
          </router-link>
        </template>
      </el-table-column>
      <el-table-column width="60px" align="center">
        <template slot="header">
          <i class="fas fa-book-open" />
        </template>
        <template slot-scope="scope">
          <span
            v-if="
              scope.row.reading_score > 0 || scope.row.reading_status !== null
            "
          >
            {{ scope.row.reading_score }}</span
          >
          <el-tag v-else type="danger" disable-transitions>
            {{ $t("courses.Incomplete") }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column width="60px" align="center">
        <template slot="header">
          <i class="fas fa-headphones-alt" />
        </template>
        <template slot-scope="scope">
          <span
            v-if="
              scope.row.listening_score > 0 ||
                scope.row.listening_status !== null
            "
          >
            {{ scope.row.listening_score }}</span
          >
          <el-tag v-else type="danger" disable-transitions>
            {{ $t("courses.Incomplete") }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column width="60px" align="center">
        <template slot="header">
          <i class="fas fa-microphone-alt" />
        </template>
        <template slot-scope="scope">
          <span
            v-if="
              scope.row.speaking_score > 0 ||
                scope.row.speaking_status === 'DONE'
            "
          >
            {{ scope.row.speaking_score }}</span
          >
          <div v-else>
            <el-tag
              size="medium"
              type="warning"
              v-if="scope.row.speaking_status === 'SCORING'"
              >Pending</el-tag
            >
            <el-tag
              v-else-if="scope.row.speaking_status === null"
              type="danger"
              disable-transitions
            >
              {{ $t("courses.Incomplete") }}
            </el-tag>
            <span v-else> - </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="60px" align="center">
        <template slot="header">
          <i class="fas fa-pencil-alt" />
        </template>
        <template slot-scope="scope">
          <span
            v-if="
              scope.row.writing_score > 0 || scope.row.writing_status === 'DONE'
            "
          >
            {{ scope.row.writing_score }}</span
          >
          <div v-else>
            <el-tag
              size="medium"
              type="warning"
              v-if="scope.row.writing_status === 'SCORING'"
              >Pending</el-tag
            >
            <el-tag
              v-else-if="scope.row.writing_status === null"
              type="danger"
              disable-transitions
            >
              {{ $t("courses.Incomplete") }}
            </el-tag>
            <span v-else> - </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="total_score" label="Score" width="70px">
        <template slot-scope="scope">
          <span v-if="scope.row.is_finished === 1">
            {{ scope.row.total_score }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="Start Date" width="110px">
        <template slot-scope="scope">
          {{ instant.formatGoogleTime(scope.row.created_at) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="progress_remaining"
        label="Time Spent"
        width="100px"
      >
        <template slot-scope="scope">
          <el-tag type="warning">
            {{ instant.formatSecondToMMSS(scope.row.progress_remaining) }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Status" width="150">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.is_finished === 0 ? 'danger' : 'success'"
            disable-transitions
          >
            <span v-if="scope.row.is_finished === 0">
              {{ $t("courses.Incomplete") }}
            </span>
            <span v-else>{{ $t("courses.completed") }}</span>
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="Actions"
        :fixed="isPhone ? false : 'right'"
        width="130"
      >
        <template slot-scope="scope">
          <router-link
            :to="{
              name: 'ToeflTest',
              query: {
                id: scope.row.exam_id,
                mode: 0,
                user_exam_id: scope.row.user_exam_id
              }
            }"
            v-if="
              scope.row.is_finished === 0 && scope.row.user_id === profile.id
            "
          >
            <el-button type="success" size="small">
              <i class="el-icon-video-play"></i> {{ $t("courses.Resume") }}
            </el-button>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column
        prop="level"
        label="Grading Type"
        :fixed="isPhone ? false : 'right'"
        width="180"
      >
        <template slot-scope="scope">
          <template v-if="scope.row.can_score == 1">
            <b
              :class="scope.row.vip === 1 ? 'vip' : ''"
              v-if="scope.row.can_suggest === 0"
            >
              Scores Only
            </b>
            <template v-else>
              <!-- Detail Comments -->
              <b
                :class="scope.row.vip === 1 ? 'vip' : ''"
                v-if="scope.row.suggest_type === 'detail'"
              >
                <i class="fas fa-crown"></i>
                VIP Comments
              </b>
              <!-- Simple Comments -->
              <b
                :class="scope.row.vip === 1 ? 'vip' : ''"
                v-if="scope.row.suggest_type === 'outline'"
              >
                Simple Comments
              </b>
            </template>
          </template>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { instant, user } from "@ivy-way/material";
import moment from "moment";
import TOEFL from "@/apis/toefl";

export default {
  props: ["tableData", "isAdmin"],
  data() {
    return {
      moment: moment
    };
  },
  computed: {
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    },
    instant() {
      return instant;
    },
    userMethods() {
      return user;
    },
    profile() {
      return this.$store.getters["user/getProfile"];
    }
  },
  watch: {},

  mounted() {},

  methods: {
    viewTranscript(id) {
      this.routerPush({
        name: "toeflResult",
        query: {
          id
        }
      });
    },
    async updateScore(id) {
      await TOEFL.updateScore(id);
      this.$router.go(0);
    }
  }
};
</script>

<style scoped>
.updateScore {
  font-size: 18px;
}
.vip {
  color: #f0ad4e;
}
</style>
