<template>
  <el-menu :default-active="active" class="el-menu-demo" mode="horizontal">
    <el-menu-item index="1" class="menu-item"
      ><b
        ><router-link :to="{ name: 'LandingPage' }" class="linkInTab">{{
          $t("toefl.TOEFL_Practices")
        }}</router-link></b
      ></el-menu-item
    >
    <el-menu-item index="2" class="menu-item"
      ><b
        ><router-link :to="{ name: 'ToeflTestResults' }" class="linkInTab">
          {{ $t("toefl.TOEFL_Results") }}</router-link
        ></b
      ></el-menu-item
    >
    <el-menu-item v-if="isAdmin" index="3" class="menu-item"
      ><el-badge :value="total" class="item" :hidden="total <= 0"
        ><b
          ><router-link :to="{ name: 'ToeflTestGrading' }" class="linkInTab">
            {{ $t("toefl.TOEFL_Grading") }}
          </router-link></b
        ></el-badge
      ></el-menu-item
    >
    <el-menu-item v-if="isAdmin && !isCom && !isCompanyAdmin" index="4" class="menu-item">
      <b
        ><router-link :to="{ name: 'ToeflAnalytics' }" class="linkInTab">
          {{ $t("header.analytics") }}
        </router-link></b
      >
    </el-menu-item>
    <el-menu-item v-if="isAdmin && !isCompanyAdmin" index="5" class="menu-item">
      <b
        ><router-link :to="{ name: 'Browse' }" class="linkInTab">
          {{ $t("header.Browse") }}
        </router-link></b
      >
    </el-menu-item>
    <el-menu-item v-if="isAdmin && !isCompanyAdmin" index="6" class="menu-item">
      <b
        ><router-link :to="{ name: 'PromotionCode' }" class="linkInTab">
          {{  $t('toefl.Promotion Code Record') }}
        </router-link></b
      >
    </el-menu-item>
  </el-menu>
</template>

<script>
import TOEFL from "@/apis/toefl.js";
import role from "@/mixins/role.js";

export default {
  mixins: [role],
  props: ["active"],
  data() {
    return {
      total: 0
    };
  },
  computed: {
    isAdmin() {
      let isAdmin = this.isRoleAdmin();
      return isAdmin;
    },
    isCompanyAdmin() {
      let isCompanyAdmin = this.isRoleCompanyAdmin();
      return isCompanyAdmin;
    }
  },
  watch: {},

  mounted() {
    if (this.isRoleAdmin()) {
      this.getNotification();
    }
  },

  methods: {
    async getNotification() {
      const res = await TOEFL.getNotification({
        user_info: this.$route.query.user_info,
        test_title: this.$route.query.test_title
      });
      this.total = res.total;
    }
  }
};
</script>

<style scoped>
.item >>> .el-badge__content.is-fixed {
  transform: translateX(100%);
}
.item >>> .el-badge__content {
  line-height: 16px;
}

.linkInTab {
  color: inherit !important;
}

.linkInTab::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
}

.linkInTab:hover {
  text-decoration: none;
}

.menu-item {
  position: relative;
}
</style>
