<template>
  <div>
    <div class="container">
      <ToeflMenu active="2" />
    </div>
    <div class="container-box">
      <div class="text-center" style="margin:15px 0">
        <el-pagination
          background
          @current-change="setPage"
          :page-size="pageSize"
          :current-page="page"
          :total="resultsTotal"
          :pager-count="isPhone ? 5 : 9"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
      <router-link :to="{ name: 'ToeflTestResultsCombine' }" class="combine-button">
        <el-button type="success" size="small">
          <i class="far fa-object-group"></i> {{ $t("button.combineCompletedTest") }}
        </el-button>
      </router-link>
      <hr />
      <el-form
        :inline="true"
        :model="search"
        class="demo-form-inline"
        style="margin-top:20px"
        size="mini"
      >
        <el-form-item label="Student">
          <el-input
            v-model="search.user_info"
            placeholder="Name/Username/Email"
          ></el-input>
        </el-form-item>
        <el-form-item label="Test Name">
          <el-input
            v-model="search.test_title"
            placeholder="Test Name"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <template>
            <el-checkbox
              v-model="search.can_score"
              :true-label="'1'"
              :false-label="'0'"
              >已解鎖成績</el-checkbox
            >
          </template>
        </el-form-item>
        <el-form-item>
          <template>
            <el-checkbox
              v-model="search.is_scored"
              :true-label="'1'"
              :false-label="'0'"
              >已有成績</el-checkbox
            >
          </template>
        </el-form-item>
        <el-form-item class="search-button-item">
          <el-button type="primary" @click="onSearch" class="search-button"
            >Search</el-button
          >
        </el-form-item>
      </el-form>
      <el-tabs v-model="type" @tab-click="handleClick">
        <el-tab-pane label="Mock" name="full_mock">
          <mock
            v-loading="loading"
            :tableData="tableData"
            :isAdmin="isAdmin"
          ></mock>
        </el-tab-pane>
        <el-tab-pane label="Quick" name="quick_mock">
          <mock
            v-loading="loading"
            :tableData="tableData"
            :isAdmin="isAdmin"
          ></mock>
        </el-tab-pane>
        <el-tab-pane label="Reading" name="reading">
          <section-test
            v-loading="loading"
            :tableData="tableData"
            :isAdmin="isAdmin"
          ></section-test>
        </el-tab-pane>
        <el-tab-pane label="Listening" name="listening">
          <section-test
            v-loading="loading"
            :tableData="tableData"
            :isAdmin="isAdmin"
          ></section-test>
        </el-tab-pane>
        <el-tab-pane label="Speaking" name="speaking">
          <section-test
            v-loading="loading"
            :tableData="tableData"
            :isAdmin="isAdmin"
          ></section-test>
        </el-tab-pane>
        <el-tab-pane label="Writing" name="writing">
          <section-test
            v-loading="loading"
            :tableData="tableData"
            :isAdmin="isAdmin"
          ></section-test>
        </el-tab-pane>
        <el-tab-pane v-if="false" label="Single Section" name="single_section">
          <section-test
            v-loading="loading"
            :tableData="tableData"
            :isAdmin="isAdmin"
          ></section-test>
        </el-tab-pane>
      </el-tabs>
      <div class="text-center" style="margin:15px 0">
        <el-pagination
          background
          @current-change="setPage"
          :page-size="pageSize"
          :current-page="page"
          :total="resultsTotal"
          :pager-count="isPhone ? 5 : 9"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import role from "@/mixins/role.js";
import TOEFL from "@/apis/toefl.js";
import ToeflMenu from "@/views/toefl/components/ToeflMenu.vue";
import Mock from "@/views/toefl/toeflTestResults/Mock.vue";
import SectionTest from "@/views/toefl/toeflTestResults/SectionTest.vue";

export default {
  metaInfo() {
    return {
      title: "Test Results - " + this.CompanyName
    };
  },

  components: {
    ToeflMenu,
    Mock,
    SectionTest
  },

  mixins: [role],

  props: [],
  data() {
    return {
      activeName: "Mock",
      tableData: null,
      results: [],
      resultsTotal: 0,
      per_page: 0,
      pageSize: 0,
      type: "full_mock",
      search: {
        user_info: "",
        test_title: "",
        is_scored: "0",
        can_score: "0"
      },
      loading: true
    };
  },
  computed: {
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    },
    isAdmin() {
      let isAdmin = this.isRoleAdmin();
      return isAdmin;
    },
    page() {
      let page = 1;
      if (this.$route.query.page === undefined) {
        page = 1;
      } else {
        page = this.$route.query.page;
      }
      return parseInt(page);
    }
  },
  watch: {},

  mounted() {
    let defaultSearch = { ...this.search };
    Object.keys(defaultSearch).forEach(key => {
      if (this.$route.query[key]) {
        defaultSearch[key] = this.$route.query[key];
      }
    });
    this.search = { ...defaultSearch };
    this.getResults();
    this.type = this.getType();
  },

  methods: {
    onSearch() {
      this.$router.replace({
        name: "ToeflTestResults",
        query: {
          type: this.getType(),
          page: this.page,
          user_info: this.search.user_info,
          test_title: this.search.test_title,
          is_scored: this.search.is_scored,
          can_score: this.search.can_score
        }
      });
    },
    async getResults() {
      let type = this.getType();
      const tableData = await TOEFL.getAllTranscripts({
        category: type,
        page: this.page,
        user_info: this.search.user_info,
        test_title: this.search.test_title,
        is_scored: this.search.is_scored,
        can_score: this.search.can_score
      });
      this.tableData = tableData.data;
      this.loading = false;
      this.resultsTotal = tableData.total;
      this.pageSize = tableData.per_page;
    },
    handleClick(e) {
      this.$router.replace({
        name: "ToeflTestResults",
        query: {
          type: e.name,
          page: 1,
          user_info: this.search.user_info,
          test_title: this.search.test_title,
          is_scored: this.search.is_scored,
          can_score: this.search.can_score
        }
      });
    },
    getType() {
      let type = "full_mock";
      if (this.$route.query.type === undefined) {
        type = "full_mock";
      } else {
        type = this.$route.query.type;
      }
      return type;
    },
    handleSelect(index) {
      if (index === "1") {
        this.$router.replace({
          name: "LandingPage"
        });
      }
    },
    setPage(page) {
      this.routerPush({
        name: "ToeflTestResults",
        query: {
          type: this.getType(),
          page: page,
          user_info: this.search.user_info,
          test_title: this.search.test_title,
          is_scored: this.search.is_scored,
          can_score: this.search.can_score
        }
      });
    }
  }
};
</script>

<style scoped>
.ivyway-title {
  margin: 20px 0;
}
::v-deep .el-form-item {
  margin-bottom: 10px;
}

::v-deep .el-checkbox {
  margin-left: 4px;
}

.container-box {
  padding: 0 30px;
}
@media screen and (max-width: 400px) {
  .container-box {
    padding: 0 15px;
  }
  ::v-deep .el-form-item {
    margin-bottom: 0;
  }
  .search-button-item {
    display: block;
  }
  ::v-deep .search-button-item .el-form-item__content {
    width: 100%;
  }
  .search-button {
    margin: 0;
    width: 100%;
  }
  .combine-button {
    width: 100%;
  }
  ::v-deep .combine-button .el-button {
    width: 100%;
  }
  ::v-deep .el-menu--horizontal > .el-menu-item {
    height: 40px;
    line-height: 40px;
  }
}
</style>
