var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":""}},[_c('el-table-column',{attrs:{"prop":"toefl_title","label":"Test"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.is_finished === 0 && !_vm.isAdmin)?_c('div',[_vm._v(" "+_vm._s(scope.row.toefl_title)+" ")]):_c('router-link',{attrs:{"to":{ name: 'toeflResult', query: { id: scope.row.user_exam_id } }}},[_c('i',{staticClass:"fa fa-eye"}),_vm._v(" "+_vm._s(scope.row.toefl_title))])]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"Student","width":"150px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.isAdmin)?_c('router-link',{attrs:{"to":{ name: 'ProfileForAdmin', params: { id: scope.row.user_id } }}},[_vm._v(" "+_vm._s(_vm.userMethods.displayName(scope.row.first_name, scope.row.last_name))+" ")]):_c('router-link',{attrs:{"to":{ name: 'Profile' }}},[_vm._v(" "+_vm._s(_vm.userMethods.displayName(scope.row.first_name, scope.row.last_name))+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"total_score","label":"Score"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
            (_vm.$route.query.type == 'speaking' ||
              _vm.$route.query.type == 'writing') &&
              scope.row.total_score == 0
          )?_c('div',[_vm._v(" - ")]):_c('div',[_vm._v(" "+_vm._s(scope.row.total_score)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"created_at","label":"Start Date"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.instant.formatGoogleTime(scope.row.created_at))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"progress_remaining","label":"Time Spent","width":"200px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tag',{attrs:{"type":"warning"}},[_vm._v(" "+_vm._s(_vm.instant.formatSecondToMMSS(scope.row.progress_remaining))+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"is_finished","label":"Status"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tag',{attrs:{"type":scope.row.is_finished === 0 ? 'danger' : 'success',"disable-transitions":""}},[(scope.row.is_finished === 0)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("courses.Incomplete"))+" ")]):_c('span',[_vm._v(_vm._s(_vm.$t("courses.completed")))])])]}}])}),(false)?_c('el-table-column',{attrs:{"label":"Actions","fixed":_vm.isPhone ? false : 'right',"width":"130"}},[_c('el-button',{staticClass:"text-danger",attrs:{"type":"text","size":"small"}},[_c('i',{staticClass:"fas fa-trash-alt"}),_vm._v(" Delete ")]),_c('el-button',{attrs:{"type":"text","size":"small"}},[_c('i',{staticClass:"fas fa-edit"}),_vm._v(" Edit ")])],1):_c('el-table-column',{attrs:{"label":"Actions","fixed":_vm.isPhone ? false : 'right',"width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.is_finished === 0 && scope.row.user_id === _vm.profile.id)?_c('router-link',{attrs:{"to":{
            name: 'ToeflTest',
            query: {
              id: scope.row.exam_id,
              mode: 0,
              user_exam_id: scope.row.user_exam_id
            }
          }}},[_c('el-button',{attrs:{"type":"success","size":"small"}},[_c('i',{staticClass:"el-icon-video-play"}),_vm._v(" "+_vm._s(_vm.$t("courses.Resume"))+" ")])],1):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"level","fixed":_vm.isPhone ? false : 'right',"label":"Grading Type","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.can_score == 1)?[(scope.row.can_suggest === 0)?_c('b',{class:scope.row.vip === 1 ? 'vip' : ''},[_vm._v(" Scores Only ")]):[(scope.row.suggest_type === 'detail')?_c('b',{class:scope.row.vip === 1 ? 'vip' : ''},[_c('i',{staticClass:"fas fa-crown"}),_vm._v(" VIP Comments ")]):_vm._e(),(scope.row.suggest_type === 'outline')?_c('b',{class:scope.row.vip === 1 ? 'vip' : ''},[_vm._v(" Simple Comments ")]):_vm._e()]]:_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }